import {Container} from "@mui/material"
import ApplicationTable from "components/ApplicationTable"
import PageHeader from "components/PageHeader"
import React, {FC} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {applicationFilters, ApplicationFilterSlug} from "routers/AdminRoutes"

const ApplicationIndex: FC = () => {
  const {filterSlug} = useParams<{filterSlug: ApplicationFilterSlug}>()
  const navigate = useNavigate()

  if (filterSlug === undefined) {
    throw new Error("Filter slug is undefined")
  }

  const applicationFilter = applicationFilters[filterSlug]

  return (
    <Container maxWidth="md">
      <PageHeader title={applicationFilter.title} />

      <ApplicationTable
        onRowClick={(application) =>
          navigate(`/applications/${filterSlug}/${application._id}`)
        }
        additionalConditions={[applicationFilter.condition]}
      />
    </Container>
  )
}

export default ApplicationIndex
