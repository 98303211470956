import {Condition} from "@lightningkite/lightning-server-simplified"
import {Application, SoleraStatus} from "api/sdk"
import {ApplicationDetail} from "pages/ApplicationDetail"
import ApplicationIndex from "pages/ApplicationIndex"
import {UserDetail} from "pages/UserDetail"
import UserIndex from "pages/UserIndex"
import React, {FC} from "react"
import {Navigate, Route, Routes} from "react-router-dom"

export enum ApplicationFilterSlug {
  NEEDS_REVIEW = "needs-review",
  NEEDS_TRUST = "needs-trust",
  AWAITING_SOLERA = "awaiting-solera",
  ALL = "all"
}

export const applicationFilters: Record<
  ApplicationFilterSlug,
  {condition: Condition<Application>; title: string}
> = {
  [ApplicationFilterSlug.NEEDS_REVIEW]: {
    title: "Needs Review",
    condition: {readyForReview: {Equal: true}}
  },
  [ApplicationFilterSlug.NEEDS_TRUST]: {
    title: "Needs Trust",
    condition: {And: [{stripe: {NotEqual: null}}, {trust: {Equal: null}}]}
  },
  [ApplicationFilterSlug.AWAITING_SOLERA]: {
    title: "Awaiting Solera",
    condition: {
      And: [
        {signature: {IfNotNull: {signed: {Equal: true}}}},
        {
          Or: [
            {solera: {Equal: null}},
            {solera: {IfNotNull: {status: {NotEqual: SoleraStatus.APPROVED}}}}
          ]
        }
      ]
    }
  },
  [ApplicationFilterSlug.ALL]: {
    title: "All Applications",
    condition: {Always: true}
  }
}

const AdminRoutes: FC = () => {
  return (
    <Routes>
      <Route>
        <Route
          path="/applications/:filterSlug"
          element={<ApplicationIndex />}
        />
        <Route
          path="/applications/:filterSlug/:applicationId"
          element={<ApplicationDetail />}
        />

        <Route path="/users" element={<UserIndex />} />
        <Route path="/users/:userId" element={<UserDetail />} />
        <Route
          path="/users/:userId/:applicationId"
          element={<ApplicationDetail />}
        />

        {/* If page doesn't exist, redirect to home */}
        <Route
          path="*"
          element={
            <Navigate
              to={`/applications/${ApplicationFilterSlug.ALL}`}
              replace
            />
          }
        />
      </Route>
    </Routes>
  )
}

export default AdminRoutes
