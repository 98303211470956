import {Box, Button, Container, Toolbar} from "@mui/material"
import {AuthContext} from "App"
import logo from "assets/logo.png"
import React, {FC, ReactNode, useContext} from "react"

export const MainLayout: FC<{children: ReactNode}> = ({children}) => {
  const {logout} = useContext(AuthContext)

  return (
    <Box bgcolor="background.default" width="100%" minHeight="100vh">
      <Toolbar sx={{borderBottom: "1px solid #DDD", backgroundColor: "white"}}>
        <img
          src={logo}
          height="50px"
          style={{alignSelf: "center", marginTop: "0.5rem"}}
        />

        <Button onClick={logout} sx={{ml: "auto"}}>
          Log Out
        </Button>
      </Toolbar>

      <Container maxWidth="md" sx={{mt: 5, pb: 7}}>
        {children}
      </Container>
    </Box>
  )
}
