import {
  AggregateQuery,
  apiCall,
  Condition,
  EntryChange,
  GroupAggregateQuery,
  GroupCountQuery,
  MassModification,
  Modification,
  Query
} from "@lightningkite/lightning-server-simplified"

export enum AccountInfoTypes {
  TRADITIONAL = "TRADITIONAL",
  ROTH = "ROTH",
  BOTH = "BOTH"
}
export interface Address {
  line1: string | null | undefined
  line2: string | null | undefined
  city: string | null | undefined
  state: string | null | undefined
  country: string | null | undefined
  postalCode: string | null | undefined
}
export interface Application {
  _id: string
  owner: string
  trustee: TrusteeInfo
  business: BusinessInfo
  accountTypes: AccountInfoTypes
  investmentCountries: Array<string>
  investmentSectors: Array<InvestmentSectors>
  existing: ExistingState | null | undefined
  originalPlan: OriginalPlanInfo | null | undefined
  spouse: SpouseParticipation | null | undefined
  spouseTrusteeInfo: SpouseTrusteeInfo | null | undefined
  desiredTrustName: string | null | undefined
  notes: string
  stripe: StripePurchaseInfo | null | undefined
  signature: ESignatureInfo | null | undefined
  solera: SoleraInfo | null | undefined
  trust: TrustInfo | null | undefined
  readyForReview: boolean
  review: SignupFormReview | null | undefined
}
export interface ApplicationHistorical {
  _id: string
  currentId: string
  timestamp: string
  state: Application
  editor: string
}
export enum BusinessForm {
  LLC = "LLC",
  C = "C",
  S = "S",
  Sole = "Sole",
  Partnership = "Partnership"
}
export interface BusinessInfo {
  name: string | null | undefined
  phone: string | null | undefined
  email: string | null | undefined
  startedAt: string | null | undefined
  address: Address | null | undefined
  businessForm: BusinessForm | null | undefined
  stateOfFormation: string | null | undefined
  entityIdentificationNumber: string | null | undefined
  naics: NAICSCode | null | undefined
  fiscalYearEnd: FiscalYearEnd | null | undefined
}
export interface DynamicSettings {
  _id: string
  trustAgreement: string | null | undefined
  adoptionAgreement: string | null | undefined
  serviceAgreement: string | null | undefined
  totalAgreements: TotalAgreements | null | undefined
  agreementRoles: Record<string, string>
}
export interface ESignatureInfo {
  id: string
  trustAgreementStart: number
  trustAgreementEnd: number
  adoptionAgreementStart: number
  adoptionAgreementEnd: number
  serviceAgreementStart: number
  serviceAgreementEnd: number
  signed: boolean
}
export interface EmailPinLogin {
  email: string
  pin: string
}
export enum ExistingState {
  None = "None",
  Both = "Both",
  Replace = "Replace"
}
export interface FiscalYearEnd {
  month: number | null | undefined
  day: number | null | undefined
}
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE"
}
export interface HealthStatus {
  level: Level
  checkedAt: string
  additionalMessage: string | null | undefined
}
export enum InvestmentSectors {
  RealEstateRental = "RealEstateRental",
  RealEstateFlip = "RealEstateFlip",
  PreciousMetals = "PreciousMetals",
  PrivateEquityCompanies = "PrivateEquityCompanies",
  StocksBondsSecurities = "StocksBondsSecurities",
  Cryptocurrency = "Cryptocurrency",
  Commodities = "Commodities",
  PurchaseNotes = "PurchaseNotes",
  TaxLiens = "TaxLiens"
}
export enum Level {
  OK = "OK",
  WARNING = "WARNING",
  URGENT = "URGENT",
  ERROR = "ERROR"
}
export interface Memory {
  max: number
  total: number
  free: number
  systemAllocated: number
  usage: number
}
export interface MetricSpanStats {
  _id: string
  endpoint: string
  type: string
  timeStamp: string
  timeSpan: string
  min: number
  max: number
  sum: number
  count: number
}
export enum NAICSCode {
  AccommodationandFoodServices = "AccommodationandFoodServices",
  AccountingLegal = "AccountingLegal",
  AgricultureForestryFishingandHunting = "AgricultureForestryFishingandHunting",
  AirlinesAviation = "AirlinesAviation",
  ArchitectureDesign = "ArchitectureDesign",
  ArtsEntertainmentandRecreation = "ArtsEntertainmentandRecreation",
  Automotive = "Automotive",
  BusinessServices = "BusinessServices",
  Cannabis = "Cannabis",
  ConstructionRepairMaintenance = "ConstructionRepairMaintenance",
  EducationalServices = "EducationalServices",
  Engineering = "Engineering",
  EnvironmentalServices = "EnvironmentalServices",
  FinancialServices = "FinancialServices",
  Insurance = "Insurance",
  HealthCareandSocialAssistance = "HealthCareandSocialAssistance",
  InformationTechnologyandWebDevelopment = "InformationTechnologyandWebDevelopment",
  LawEnforcement = "LawEnforcement",
  ManagementConsulting = "ManagementConsulting",
  Manufacturing = "Manufacturing",
  MediaincMarketingandAdvertising = "MediaincMarketingandAdvertising",
  Military = "Military",
  NonProfitOrganizationManagement = "NonProfitOrganizationManagement",
  OilEnergy = "OilEnergy",
  ProfessionalScientificandTechnicalServices = "ProfessionalScientificandTechnicalServices",
  PublicAdministration = "PublicAdministration",
  RealEstateandRentalandLeasing = "RealEstateandRentalandLeasing",
  RetailandEcommerce = "RetailandEcommerce",
  TransportationandWarehousing = "TransportationandWarehousing",
  Utilities = "Utilities",
  WasteManagementandRemediationServices = "WasteManagementandRemediationServices",
  WholesaleTrade = "WholesaleTrade"
}
export interface OriginalPlanInfo {
  originalPlanDate: string | null | undefined
  originalTrustEin: string | null | undefined
}
export interface ServerHealth {
  serverId: string
  version: string
  memory: Memory
  features: Record<string, HealthStatus>
  loadAverageCpu: number
}
export interface SignupFormReview {
  status: SignupFormStatus
  at: string
  notes: string
}
export enum SignupFormStatus {
  ChangesRequested = "ChangesRequested",
  Rejected = "Rejected",
  Approved = "Approved"
}
export interface SoleraInfo {
  id: string
  status: SoleraStatus
}
export enum SoleraStatus {
  DRAFT = "DRAFT",
  REQUIRES_REVIEW = "REQUIRES_REVIEW",
  READY_FOR_BATCH = "READY_FOR_BATCH",
  IN_PROCESS = "IN_PROCESS",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  ON_HOLD = "ON_HOLD"
}
export enum SpouseParticipation {
  CoTrustee = "CoTrustee",
  Participating = "Participating",
  NotParticipating = "NotParticipating",
  Single = "Single",
  Divorced = "Divorced",
  Widowed = "Widowed"
}
export interface SpouseTrusteeInfo {
  fullLegalName: string | null | undefined
  email: string | null | undefined
}
export interface StripePurchaseInfo {
  subscriptionId: string
  active: boolean
}
export interface TotalAgreements {
  file: string
  trustAgreementStart: number
  trustAgreementEnd: number
  adoptionAgreementStart: number
  adoptionAgreementEnd: number
  serviceAgreementStart: number
  serviceAgreementEnd: number
}
export interface TrustInfo {
  ein: string | null | undefined
  name: string | null | undefined
  address: Address | null | undefined
  creationDate: string | null | undefined
  state: string | null | undefined
}
export interface TrusteeInfo {
  givenName: string | null | undefined
  middleName: string | null | undefined
  surname: string | null | undefined
  maidenName: string | null | undefined
  gender: Gender | null | undefined
  mailing: Address | null | undefined
  previous: Address | null | undefined
  residential: Address | null | undefined
  phone: string | null | undefined
  socialSecurityNumber: string | null | undefined
  birthday: string | null | undefined
}
export interface UploadInformation {
  uploadUrl: string
  futureCallToken: string
}
export interface User {
  _id: string
  email: string
  isSuperUser: boolean
  stripe: string | null | undefined
}

export interface Api {
  readonly auth: {
    refreshToken(userToken: string): Promise<string>
    getSelf(userToken: string): Promise<User>
    emailLoginLink(input: string): Promise<void>
    emailPINLogin(input: EmailPinLogin): Promise<string>
  }
  readonly user: {
    default(userToken: string): Promise<User>
    query(input: Query<User>, userToken: string): Promise<Array<User>>
    detail(id: string, userToken: string): Promise<User>
    insertBulk(input: Array<User>, userToken: string): Promise<Array<User>>
    insert(input: User, userToken: string): Promise<User>
    upsert(id: string, input: User, userToken: string): Promise<User>
    bulkReplace(input: Array<User>, userToken: string): Promise<Array<User>>
    replace(id: string, input: User, userToken: string): Promise<User>
    bulkModify(
      input: MassModification<User>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<EntryChange<User>>
    modify(
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<User>
    bulkDelete(input: Condition<User>, userToken: string): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(input: Condition<User>, userToken: string): Promise<number>
    groupCount(
      input: GroupCountQuery<User>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<User>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<User>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  readonly application: {
    default(userToken: string): Promise<Application>
    query(
      input: Query<Application>,
      userToken: string
    ): Promise<Array<Application>>
    detail(id: string, userToken: string): Promise<Application>
    insertBulk(
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>>
    insert(input: Application, userToken: string): Promise<Application>
    upsert(
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application>
    bulkReplace(
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>>
    replace(
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application>
    bulkModify(
      input: MassModification<Application>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<EntryChange<Application>>
    modify(
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<Application>
    bulkDelete(
      input: Condition<Application>,
      userToken: string
    ): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(input: Condition<Application>, userToken: string): Promise<number>
    groupCount(
      input: GroupCountQuery<Application>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<Application>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<Application>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  readonly applicationHistorical: {
    default(userToken: string): Promise<ApplicationHistorical>
    query(
      input: Query<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>>
    detail(id: string, userToken: string): Promise<ApplicationHistorical>
    insertBulk(
      input: Array<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>>
    insert(
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical>
    upsert(
      id: string,
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical>
    bulkReplace(
      input: Array<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>>
    replace(
      id: string,
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical>
    bulkModify(
      input: MassModification<ApplicationHistorical>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<ApplicationHistorical>,
      userToken: string
    ): Promise<EntryChange<ApplicationHistorical>>
    modify(
      id: string,
      input: Modification<ApplicationHistorical>,
      userToken: string
    ): Promise<ApplicationHistorical>
    bulkDelete(
      input: Condition<ApplicationHistorical>,
      userToken: string
    ): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(
      input: Condition<ApplicationHistorical>,
      userToken: string
    ): Promise<number>
    groupCount(
      input: GroupCountQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  readonly dynamicSettings: {
    default(userToken: string): Promise<DynamicSettings>
    query(
      input: Query<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>>
    detail(id: string, userToken: string): Promise<DynamicSettings>
    insertBulk(
      input: Array<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>>
    insert(input: DynamicSettings, userToken: string): Promise<DynamicSettings>
    upsert(
      id: string,
      input: DynamicSettings,
      userToken: string
    ): Promise<DynamicSettings>
    bulkReplace(
      input: Array<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>>
    replace(
      id: string,
      input: DynamicSettings,
      userToken: string
    ): Promise<DynamicSettings>
    bulkModify(
      input: MassModification<DynamicSettings>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<DynamicSettings>,
      userToken: string
    ): Promise<EntryChange<DynamicSettings>>
    modify(
      id: string,
      input: Modification<DynamicSettings>,
      userToken: string
    ): Promise<DynamicSettings>
    bulkDelete(
      input: Condition<DynamicSettings>,
      userToken: string
    ): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(input: Condition<DynamicSettings>, userToken: string): Promise<number>
    groupCount(
      input: GroupCountQuery<DynamicSettings>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<DynamicSettings>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<DynamicSettings>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  uploadFileForRequest(): Promise<UploadInformation>
  resetSigning(id: string, userToken: string): Promise<void>
  downloadDocumentation(id: string, userToken: string): Promise<string>
  downloadAdoptionDocumentation(id: string, userToken: string): Promise<string>
  resetSolera(id: string, userToken: string): Promise<void>
  getServerHealth(userToken: string): Promise<ServerHealth>
  getMetrics(): Promise<string>
}

export class UserSession {
  constructor(public api: Api, public userToken: string) {}
  resetSigning(id: string): Promise<void> {
    return this.api.resetSigning(id, this.userToken)
  }

  downloadDocumentation(id: string): Promise<string> {
    return this.api.downloadDocumentation(id, this.userToken)
  }

  downloadAdoptionDocumentation(id: string): Promise<string> {
    return this.api.downloadAdoptionDocumentation(id, this.userToken)
  }

  resetSolera(id: string): Promise<void> {
    return this.api.resetSolera(id, this.userToken)
  }

  getServerHealth(): Promise<ServerHealth> {
    return this.api.getServerHealth(this.userToken)
  }

  uploadFileForRequest(): Promise<UploadInformation> {
    return this.api.uploadFileForRequest()
  }

  getMetrics(): Promise<string> {
    return this.api.getMetrics()
  }

  readonly auth = {
    refreshToken: (): Promise<string> => {
      return this.api.auth.refreshToken(this.userToken)
    },
    getSelf: (): Promise<User> => {
      return this.api.auth.getSelf(this.userToken)
    },
    emailLoginLink: (input: string): Promise<void> => {
      return this.api.auth.emailLoginLink(input)
    },
    emailPINLogin: (input: EmailPinLogin): Promise<string> => {
      return this.api.auth.emailPINLogin(input)
    }
  }

  readonly user = {
    default: (): Promise<User> => {
      return this.api.user.default(this.userToken)
    },
    query: (input: Query<User>): Promise<Array<User>> => {
      return this.api.user.query(input, this.userToken)
    },
    detail: (id: string): Promise<User> => {
      return this.api.user.detail(id, this.userToken)
    },
    insertBulk: (input: Array<User>): Promise<Array<User>> => {
      return this.api.user.insertBulk(input, this.userToken)
    },
    insert: (input: User): Promise<User> => {
      return this.api.user.insert(input, this.userToken)
    },
    upsert: (id: string, input: User): Promise<User> => {
      return this.api.user.upsert(id, input, this.userToken)
    },
    bulkReplace: (input: Array<User>): Promise<Array<User>> => {
      return this.api.user.bulkReplace(input, this.userToken)
    },
    replace: (id: string, input: User): Promise<User> => {
      return this.api.user.replace(id, input, this.userToken)
    },
    bulkModify: (input: MassModification<User>): Promise<number> => {
      return this.api.user.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<User>
    ): Promise<EntryChange<User>> => {
      return this.api.user.modifyWithDiff(id, input, this.userToken)
    },
    modify: (id: string, input: Modification<User>): Promise<User> => {
      return this.api.user.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<User>): Promise<number> => {
      return this.api.user.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.user.delete(id, this.userToken)
    },
    count: (input: Condition<User>): Promise<number> => {
      return this.api.user.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<User>
    ): Promise<Record<string, number>> => {
      return this.api.user.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<User>
    ): Promise<number | null | undefined> => {
      return this.api.user.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<User>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.user.groupAggregate(input, this.userToken)
    }
  }

  readonly application = {
    default: (): Promise<Application> => {
      return this.api.application.default(this.userToken)
    },
    query: (input: Query<Application>): Promise<Array<Application>> => {
      return this.api.application.query(input, this.userToken)
    },
    detail: (id: string): Promise<Application> => {
      return this.api.application.detail(id, this.userToken)
    },
    insertBulk: (input: Array<Application>): Promise<Array<Application>> => {
      return this.api.application.insertBulk(input, this.userToken)
    },
    insert: (input: Application): Promise<Application> => {
      return this.api.application.insert(input, this.userToken)
    },
    upsert: (id: string, input: Application): Promise<Application> => {
      return this.api.application.upsert(id, input, this.userToken)
    },
    bulkReplace: (input: Array<Application>): Promise<Array<Application>> => {
      return this.api.application.bulkReplace(input, this.userToken)
    },
    replace: (id: string, input: Application): Promise<Application> => {
      return this.api.application.replace(id, input, this.userToken)
    },
    bulkModify: (input: MassModification<Application>): Promise<number> => {
      return this.api.application.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<Application>
    ): Promise<EntryChange<Application>> => {
      return this.api.application.modifyWithDiff(id, input, this.userToken)
    },
    modify: (
      id: string,
      input: Modification<Application>
    ): Promise<Application> => {
      return this.api.application.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<Application>): Promise<number> => {
      return this.api.application.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.application.delete(id, this.userToken)
    },
    count: (input: Condition<Application>): Promise<number> => {
      return this.api.application.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<Application>
    ): Promise<Record<string, number>> => {
      return this.api.application.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<Application>
    ): Promise<number | null | undefined> => {
      return this.api.application.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<Application>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.application.groupAggregate(input, this.userToken)
    }
  }

  readonly applicationHistorical = {
    default: (): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.default(this.userToken)
    },
    query: (
      input: Query<ApplicationHistorical>
    ): Promise<Array<ApplicationHistorical>> => {
      return this.api.applicationHistorical.query(input, this.userToken)
    },
    detail: (id: string): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.detail(id, this.userToken)
    },
    insertBulk: (
      input: Array<ApplicationHistorical>
    ): Promise<Array<ApplicationHistorical>> => {
      return this.api.applicationHistorical.insertBulk(input, this.userToken)
    },
    insert: (input: ApplicationHistorical): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.insert(input, this.userToken)
    },
    upsert: (
      id: string,
      input: ApplicationHistorical
    ): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.upsert(id, input, this.userToken)
    },
    bulkReplace: (
      input: Array<ApplicationHistorical>
    ): Promise<Array<ApplicationHistorical>> => {
      return this.api.applicationHistorical.bulkReplace(input, this.userToken)
    },
    replace: (
      id: string,
      input: ApplicationHistorical
    ): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.replace(id, input, this.userToken)
    },
    bulkModify: (
      input: MassModification<ApplicationHistorical>
    ): Promise<number> => {
      return this.api.applicationHistorical.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<ApplicationHistorical>
    ): Promise<EntryChange<ApplicationHistorical>> => {
      return this.api.applicationHistorical.modifyWithDiff(
        id,
        input,
        this.userToken
      )
    },
    modify: (
      id: string,
      input: Modification<ApplicationHistorical>
    ): Promise<ApplicationHistorical> => {
      return this.api.applicationHistorical.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<ApplicationHistorical>): Promise<number> => {
      return this.api.applicationHistorical.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.applicationHistorical.delete(id, this.userToken)
    },
    count: (input: Condition<ApplicationHistorical>): Promise<number> => {
      return this.api.applicationHistorical.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<ApplicationHistorical>
    ): Promise<Record<string, number>> => {
      return this.api.applicationHistorical.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<ApplicationHistorical>
    ): Promise<number | null | undefined> => {
      return this.api.applicationHistorical.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<ApplicationHistorical>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.applicationHistorical.groupAggregate(
        input,
        this.userToken
      )
    }
  }

  readonly dynamicSettings = {
    default: (): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.default(this.userToken)
    },
    query: (input: Query<DynamicSettings>): Promise<Array<DynamicSettings>> => {
      return this.api.dynamicSettings.query(input, this.userToken)
    },
    detail: (id: string): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.detail(id, this.userToken)
    },
    insertBulk: (
      input: Array<DynamicSettings>
    ): Promise<Array<DynamicSettings>> => {
      return this.api.dynamicSettings.insertBulk(input, this.userToken)
    },
    insert: (input: DynamicSettings): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.insert(input, this.userToken)
    },
    upsert: (id: string, input: DynamicSettings): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.upsert(id, input, this.userToken)
    },
    bulkReplace: (
      input: Array<DynamicSettings>
    ): Promise<Array<DynamicSettings>> => {
      return this.api.dynamicSettings.bulkReplace(input, this.userToken)
    },
    replace: (id: string, input: DynamicSettings): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.replace(id, input, this.userToken)
    },
    bulkModify: (input: MassModification<DynamicSettings>): Promise<number> => {
      return this.api.dynamicSettings.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<DynamicSettings>
    ): Promise<EntryChange<DynamicSettings>> => {
      return this.api.dynamicSettings.modifyWithDiff(id, input, this.userToken)
    },
    modify: (
      id: string,
      input: Modification<DynamicSettings>
    ): Promise<DynamicSettings> => {
      return this.api.dynamicSettings.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<DynamicSettings>): Promise<number> => {
      return this.api.dynamicSettings.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.dynamicSettings.delete(id, this.userToken)
    },
    count: (input: Condition<DynamicSettings>): Promise<number> => {
      return this.api.dynamicSettings.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<DynamicSettings>
    ): Promise<Record<string, number>> => {
      return this.api.dynamicSettings.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<DynamicSettings>
    ): Promise<number | null | undefined> => {
      return this.api.dynamicSettings.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<DynamicSettings>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.dynamicSettings.groupAggregate(input, this.userToken)
    }
  }
}

export class LiveApi implements Api {
  public constructor(
    public httpUrl: string,
    public socketUrl: string = httpUrl,
    public extraHeaders: Record<string, string> = {}
  ) {}

  uploadFileForRequest(): Promise<UploadInformation> {
    return apiCall(`${this.httpUrl}/upload-early`, undefined, {
      method: "GET"
    }).then((x) => x.json())
  }

  resetSigning(id: string, userToken: string): Promise<void> {
    return apiCall(`${this.httpUrl}/application/signing/${id}`, undefined, {
      method: "GET",
      headers: userToken
        ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
        : this.extraHeaders
    }).then((x) => undefined)
  }

  downloadDocumentation(id: string, userToken: string): Promise<string> {
    return apiCall(
      `${this.httpUrl}/application/documentation/${id}`,
      undefined,
      {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }
    ).then((x) => x.json())
  }

  downloadAdoptionDocumentation(
    id: string,
    userToken: string
  ): Promise<string> {
    return apiCall(
      `${this.httpUrl}/application/documentation-adoption/${id}`,
      undefined,
      {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }
    ).then((x) => x.json())
  }

  resetSolera(id: string, userToken: string): Promise<void> {
    return apiCall(`${this.httpUrl}/application/solera/${id}`, undefined, {
      method: "GET",
      headers: userToken
        ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
        : this.extraHeaders
    }).then((x) => undefined)
  }

  getServerHealth(userToken: string): Promise<ServerHealth> {
    return apiCall(`${this.httpUrl}/meta/health`, undefined, {
      method: "GET",
      headers: userToken
        ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
        : this.extraHeaders
    }).then((x) => x.json())
  }

  getMetrics(): Promise<string> {
    return apiCall(`${this.httpUrl}/metrics/clear`, undefined, {
      method: "GET"
    }).then((x) => x.json())
  }

  readonly auth = {
    refreshToken: (userToken: string): Promise<string> => {
      return apiCall(`${this.httpUrl}/auth/refresh-token`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    getSelf: (userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/auth/self`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    emailLoginLink: (input: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/auth/login-email`, input, {
        method: "POST"
      }).then((x) => undefined)
    },
    emailPINLogin: (input: EmailPinLogin): Promise<string> => {
      return apiCall(`${this.httpUrl}/auth/login-email-pin`, input, {
        method: "POST"
      }).then((x) => x.json())
    }
  }

  readonly user = {
    default: (userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/_default_`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    query: (input: Query<User>, userToken: string): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/query`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insertBulk: (
      input: Array<User>,
      userToken: string
    ): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insert: (input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (id: string, input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<User>,
      userToken: string
    ): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (id: string, input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<User>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<EntryChange<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}/delta`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<User>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk-delete`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
        method: "DELETE",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => undefined)
    },
    count: (input: Condition<User>, userToken: string): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<User>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(`${this.httpUrl}/user/rest/group-count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<User>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(`${this.httpUrl}/user/rest/aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<User>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(`${this.httpUrl}/user/rest/group-aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    }
  }

  readonly application = {
    default: (userToken: string): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/_default_`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    query: (
      input: Query<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/query`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insertBulk: (
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insert: (input: Application, userToken: string): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<EntryChange<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}/delta`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk-delete`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
        method: "DELETE",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => undefined)
    },
    count: (
      input: Condition<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<Application>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(`${this.httpUrl}/application/rest/group-count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<Application>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(`${this.httpUrl}/application/rest/aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<Application>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(
        `${this.httpUrl}/application/rest/group-aggregate`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    }
  }

  readonly applicationHistorical = {
    default: (userToken: string): Promise<ApplicationHistorical> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/_default_`,
        undefined,
        {
          method: "GET",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    query: (
      input: Query<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/query`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<ApplicationHistorical> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}`,
        undefined,
        {
          method: "GET",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    insertBulk: (
      input: Array<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/bulk`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    insert: (
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical> => {
      return apiCall(`${this.httpUrl}/application-historical/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (
      id: string,
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<ApplicationHistorical>,
      userToken: string
    ): Promise<Array<ApplicationHistorical>> => {
      return apiCall(`${this.httpUrl}/application-historical/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (
      id: string,
      input: ApplicationHistorical,
      userToken: string
    ): Promise<ApplicationHistorical> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}`,
        input,
        {
          method: "PUT",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<ApplicationHistorical>,
      userToken: string
    ): Promise<number> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/bulk`,
        input,
        {
          method: "PATCH",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<ApplicationHistorical>,
      userToken: string
    ): Promise<EntryChange<ApplicationHistorical>> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}/delta`,
        input,
        {
          method: "PATCH",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<ApplicationHistorical>,
      userToken: string
    ): Promise<ApplicationHistorical> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}`,
        input,
        {
          method: "PATCH",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<ApplicationHistorical>,
      userToken: string
    ): Promise<number> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/bulk-delete`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/${id}`,
        undefined,
        {
          method: "DELETE",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => undefined)
    },
    count: (
      input: Condition<ApplicationHistorical>,
      userToken: string
    ): Promise<number> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/count`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/group-count`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/aggregate`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<ApplicationHistorical>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(
        `${this.httpUrl}/application-historical/rest/group-aggregate`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    }
  }

  readonly dynamicSettings = {
    default: (userToken: string): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest/_default_`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    query: (
      input: Query<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>> => {
      return apiCall(`${this.httpUrl}/settings/rest/query`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insertBulk: (
      input: Array<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>> => {
      return apiCall(`${this.httpUrl}/settings/rest/bulk`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insert: (
      input: DynamicSettings,
      userToken: string
    ): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (
      id: string,
      input: DynamicSettings,
      userToken: string
    ): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<DynamicSettings>,
      userToken: string
    ): Promise<Array<DynamicSettings>> => {
      return apiCall(`${this.httpUrl}/settings/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (
      id: string,
      input: DynamicSettings,
      userToken: string
    ): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<DynamicSettings>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/settings/rest/bulk`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<DynamicSettings>,
      userToken: string
    ): Promise<EntryChange<DynamicSettings>> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}/delta`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<DynamicSettings>,
      userToken: string
    ): Promise<DynamicSettings> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<DynamicSettings>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/settings/rest/bulk-delete`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/settings/rest/${id}`, undefined, {
        method: "DELETE",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => undefined)
    },
    count: (
      input: Condition<DynamicSettings>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/settings/rest/count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<DynamicSettings>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(`${this.httpUrl}/settings/rest/group-count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<DynamicSettings>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(`${this.httpUrl}/settings/rest/aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<DynamicSettings>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(`${this.httpUrl}/settings/rest/group-aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    }
  }
}
