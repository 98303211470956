import {faker} from "@faker-js/faker"
import {
  AccountInfoTypes,
  Address,
  Application,
  BusinessForm,
  BusinessInfo,
  ESignatureInfo,
  ExistingState,
  Gender,
  InvestmentSectors,
  NAICSCode,
  OriginalPlanInfo,
  SignupFormReview,
  SignupFormStatus,
  SoleraInfo,
  SoleraStatus,
  SpouseParticipation,
  SpouseTrusteeInfo,
  StripePurchaseInfo,
  TrusteeInfo,
  TrustInfo,
  User
} from "api/sdk"
import {stateCodeOptions} from "utils/constants"

export function generateApplicationsForUsers(users: User[]): Application[] {
  return users.map((user) => ({
    _id: faker.datatype.uuid(),
    owner: user._id,
    trustee: generateTrusteeInfo(),
    business: generateBusinessInfo(),
    accountTypes: generateAccountTypeInfo(),
    investmentCountries: generateInvestmentCountriesInfo(),
    investmentSectors: generateInvestmentSectorsInfo(),
    existing: ExistingState.Replace,
    originalPlan: generateOriginalPlanInfo(),
    spouse: SpouseParticipation.Participating,
    spouseTrusteeInfo: generateSpouseTrusteeInfo(),
    desiredTrustName: faker.company.name() + " Trust",
    notes: faker.lorem.sentences(3),
    stripe: generateStripePurchaseInfo(),
    signature: generateESignatureInfo(),
    solera: generateSoleraInfo(),
    trust: generateTrustInfo(),
    readyForReview: faker.datatype.boolean(),
    review: generateSignupFormReview()
  }))
}

function generateTrusteeInfo(): TrusteeInfo {
  return {
    givenName: faker.name.firstName(),
    middleName: faker.name.middleName(),
    surname: faker.name.lastName(),
    maidenName: faker.name.lastName(),
    gender: Gender.FEMALE,
    mailing: null,
    previous: generateAddress(),
    residential: generateAddress(),
    phone: faker.phone.number(),
    socialSecurityNumber: `${faker.finance.pin(3)}-${faker.finance.pin(
      2
    )}-${faker.finance.pin(4)}`,
    birthday: faker.date.past(100).toISOString()
  }
}

function generateAddress(): Address {
  const twoLines = faker.datatype.boolean()

  return {
    line1: faker.address.streetAddress(!twoLines),
    line2: twoLines ? faker.address.secondaryAddress() : null,
    city: faker.address.city(),
    state: faker.helpers.arrayElement(stateCodeOptions).name,
    country: "United States",
    postalCode: faker.address.zipCode()
  }
}

function generateBusinessInfo(): BusinessInfo {
  return {
    name: faker.company.name(),
    phone: faker.phone.number(),
    email: faker.internet.email(),
    startedAt: faker.date.past(50).toISOString(),
    address: generateAddress(),
    businessForm: faker.helpers.arrayElement(Object.values(BusinessForm)),
    stateOfFormation: faker.address.stateAbbr(),
    entityIdentificationNumber: `${faker.finance.pin(2)}-${faker.finance.pin(
      7
    )}`,
    naics: faker.helpers.arrayElement(Object.values(NAICSCode)),
    fiscalYearEnd: {
      month: 12,
      day: 31
    }
  }
}

function generateSpouseTrusteeInfo(): SpouseTrusteeInfo {
  const name = faker.name.fullName()
  return {
    fullLegalName: name,
    email: faker.internet.exampleEmail(name)
  }
}

function generateOriginalPlanInfo(): OriginalPlanInfo {
  return {
    originalPlanDate: faker.date.past(20).toISOString(),
    originalTrustEin: `${faker.finance.pin(2)}-${faker.finance.pin(7)}`
  }
}

function generateStripePurchaseInfo(): StripePurchaseInfo {
  return {
    subscriptionId: faker.datatype.uuid(),
    active: true
  }
}

function generateESignatureInfo(): ESignatureInfo {
  return {
    id: faker.datatype.uuid(),
    trustAgreementStart: 0,
    trustAgreementEnd: 0,
    adoptionAgreementStart: 0,
    adoptionAgreementEnd: 0,
    serviceAgreementStart: 0,
    serviceAgreementEnd: 0,
    signed: true
  }
}

function generateSignupFormReview(): SignupFormReview {
  return {
    status: SignupFormStatus.ChangesRequested,
    at: faker.date.recent(4).toISOString(),
    notes: faker.lorem.sentences(2)
  }
}

function generateSoleraInfo(): SoleraInfo {
  return {
    id: faker.datatype.uuid(),
    status: faker.helpers.arrayElement(Object.values(SoleraStatus))
  }
}

function generateAccountTypeInfo(): AccountInfoTypes {
  return faker.helpers.arrayElement(Object.values(AccountInfoTypes))
}

function generateInvestmentCountriesInfo(): string[] {
  // generate a random number of countries
  const numCountries = faker.datatype.number({min: 1, max: 5})
  const countries = []
  for (let i = 0; i < numCountries; i++) {
    countries.push(faker.address.country())
  }
  return countries
}

function generateInvestmentSectorsInfo(): InvestmentSectors[] {
  // generate a random number of sectors
  return faker.helpers.arrayElements(Object.values(InvestmentSectors))
}

function generateTrustInfo(): TrustInfo {
  return {
    ein: `${faker.finance.pin(2)}-${faker.finance.pin(7)}`,
    name: faker.company.name() + " Trust",
    address: generateAddress(),
    creationDate: faker.date.past(20).toISOString(),
    state: faker.address.stateAbbr()
  }
}
