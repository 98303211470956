import {
  makeFormikDateTimePickerProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {Button, capitalize, Stack, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {Gender} from "api/sdk"
import {AddressForm} from "components/AddressForm"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import {makeFormikRadioInputProps, RadioInput} from "components/RadioInput"
import dayjs from "dayjs"
import {useFormik} from "formik"
import React, {FC} from "react"
import {emptyTrusteeInfo} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  givenName: yup.string().required("Required"),
  surname: yup.string().required("Required"),
  gender: yup.string().required("Required"),

  residentialaddress1: yup.string().required("Required"),
  residentialcity: yup.string().required("Required"),
  residentialstate: yup.string().required("Required"),
  residentialpostalCode: yup.string().required("Required"),
  residentialcountry: yup.string().required("Required"),

  mailingaddress1: yup.string().required("Required"),
  mailingcity: yup.string().required("Required"),
  mailingstate: yup.string().required("Required"),
  mailingpostalCode: yup.string().required("Required"),
  mailingcountry: yup.string().required("Required"),

  phone: yup
    .string()
    .matches(/^\d{10,12}$/, "Phone number must be 10-12 digits")
    .required("Required"),
  ssn: yup.string().required("Required"),
  dateOfBirth: yup.date().required("Required")
})

const Trustee: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const formik = useFormik({
    initialValues: {
      givenName: application.trustee?.givenName ?? "",
      middleName: application.trustee?.middleName ?? "",
      maidenName: application.trustee?.maidenName ?? "",
      surname: application.trustee?.surname ?? "",
      gender: application.trustee.gender ?? "",

      residentialaddress1: application.trustee?.residential?.line1 ?? "",
      residentialaddress2: application.trustee?.residential?.line2 ?? "",
      residentialcity: application.trustee?.residential?.city ?? "",
      residentialstate: application.trustee?.residential?.state ?? "",
      residentialpostalCode: application.trustee?.residential?.postalCode ?? "",
      residentialcountry:
        application.trustee?.residential?.country ?? "United States",

      mailingaddress1: application.trustee?.mailing?.line1 ?? "",
      mailingaddress2: application.trustee?.mailing?.line2 ?? "",
      mailingcity: application.trustee?.mailing?.city ?? "",
      mailingstate: application.trustee?.mailing?.state ?? "",
      mailingpostalCode: application.trustee?.mailing?.postalCode ?? "",
      mailingcountry: application.trustee?.mailing?.country ?? "United States",

      previousaddress1: application.trustee?.previous?.line1 ?? "",
      previousaddress2: application.trustee?.previous?.line2 ?? "",
      previouscity: application.trustee?.previous?.city ?? "",
      previousstate: application.trustee?.previous?.state ?? "",
      previouspostalCode: application.trustee?.previous?.postalCode ?? "",
      previouscountry:
        application.trustee?.previous?.country ?? "United States",

      phone: application.trustee?.phone ?? "",
      ssn: application.trustee?.socialSecurityNumber ?? "",
      dateOfBirth: application.trustee?.birthday
        ? new Date(application.trustee?.birthday)
        : null
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return saveProgress({
        trustee: {
          ...(application.trustee ?? emptyTrusteeInfo),
          givenName: values.givenName,
          middleName: values.middleName,
          maidenName: values.maidenName,
          surname: values.surname,
          gender: values.gender as Gender,
          residential: {
            line1: values.residentialaddress1,
            line2: values.residentialaddress2,
            city: values.residentialcity,
            state: values.residentialstate,
            postalCode: values.residentialpostalCode,
            country: values.residentialcountry
          },
          mailing: {
            line1: values.mailingaddress1,
            line2: values.mailingaddress2,
            city: values.mailingcity,
            state: values.mailingstate,
            postalCode: values.mailingpostalCode,
            country: values.mailingcountry
          },
          previous: {
            line1: values.previousaddress1,
            line2: values.previousaddress2,
            city: values.previouscity,
            state: values.previousstate,
            postalCode: values.previouspostalCode,
            country: values.previouscountry
          },
          phone: values.phone,
          socialSecurityNumber: values.ssn,
          birthday: values.dateOfBirth?.toISOString().split("T")[0]
        }
      })
        .then(() => {
          resetForm({values})
          nextStep()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection title="Your Self" disableTopPadding>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="First Name"
            {...makeFormikTextFieldProps(formik, "givenName")}
          />
          <TextField
            fullWidth
            label="Middle Name"
            {...makeFormikTextFieldProps(formik, "middleName")}
          />
          <TextField
            fullWidth
            label="Last Name"
            {...makeFormikTextFieldProps(formik, "surname")}
          />
        </Stack>

        <TextField
          fullWidth
          label="Maiden Name"
          {...makeFormikTextFieldProps(formik, "maidenName")}
        />

        <RadioInput
          label="Gender"
          options={Object.values(Gender).map((g) => ({
            value: g,
            label: capitalize(g.toLowerCase())
          }))}
          {...makeFormikRadioInputProps(formik, "gender")}
        />

        <TextField
          label="Phone"
          {...makeFormikTextFieldProps(formik, "phone")}
          onChange={(e) => {
            // Only allow numbers
            const onlyNumbers = e.target.value.replace(/[^0-9]/g, "")
            formik.setFieldValue("phone", onlyNumbers)
          }}
        />
        <TextField
          label="Social Security Number"
          {...makeFormikTextFieldProps(formik, "ssn")}
        />
        <DatePicker
          label="Birthday"
          {...makeFormikDateTimePickerProps(formik, "dateOfBirth")}
          minDate={dayjs().subtract(120, "year")}
          maxDate={dayjs()}
        />
      </FormSection>

      <FormSection title="Residential Address">
        <AddressForm formik={formik} formikAddressValuePrefix="residential" />
      </FormSection>

      <FormSection
        title="Mailing Address"
        action={
          <Button
            size="small"
            onClick={() => {
              formik.setValues({
                ...formik.values,
                mailingaddress1: formik.values.residentialaddress1,
                mailingaddress2: formik.values.residentialaddress2,
                mailingcity: formik.values.residentialcity,
                mailingstate: formik.values.residentialstate,
                mailingpostalCode: formik.values.residentialpostalCode,
                mailingcountry: formik.values.residentialcountry
              })
            }}
          >
            Copy Residential Address
          </Button>
        }
      >
        <AddressForm formik={formik} formikAddressValuePrefix="mailing" />
      </FormSection>

      <FormSection title="Previous Address">
        <AddressForm formik={formik} formikAddressValuePrefix="previous" />
      </FormSection>

      <AutoLoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={formik.submitForm}
        sx={{mt: 4}}
      >
        Save and Continue
      </AutoLoadingButton>
    </>
  )
}

export default Trustee
