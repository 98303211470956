import { faker } from "@faker-js/faker";
import { Application, ApplicationHistorical, User } from "api/sdk";

export function generateApplicationHistoricalsForUsers(applications:Application[],users:User[]): ApplicationHistorical[] {
    return applications.map((application) => ({
        _id: faker.datatype.uuid(),
        currentId: application._id,
        timestamp: faker.date.past(100).toISOString(),
        state: application,
        editor: faker.helpers.arrayElement(users)._id,
    }));
}
