import { generateApplicationHistoricalsForUsers } from "./mocks/applicationHistoricals"
import {generateApplicationsForUsers} from "./mocks/applications"
import {generateUsers} from "./mocks/users"
import {Application, ApplicationHistorical, User} from "./sdk"

export interface MockDatastore {
  users: User[]
  applications: Application[]
  applicationHistoricals: ApplicationHistorical[]
}

export const generateMockDatastore = (): MockDatastore => {
  const users = generateUsers(25)
  const applications = generateApplicationsForUsers(users)
  const applicationHistoricals = generateApplicationHistoricalsForUsers(applications,users)

  return {users, applications, applicationHistoricals}
}
