import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {MenuItem, Stack, TextField} from "@mui/material"
import {useFormik} from "formik"
import React, {FC} from "react"
import {stateCodeOptions} from "utils/constants"

export interface AddressFormProps {
  formik: ReturnType<typeof useFormik<any>>
  formikAddressValuePrefix?: string
}

export const AddressForm: FC<AddressFormProps> = (props) => {
  const {formik, formikAddressValuePrefix = ""} = props

  return (
    <>
      <TextField
        label="Street Address"
        {...makeFormikTextFieldProps(
          formik,
          `${formikAddressValuePrefix}address1`
        )}
      />
      <TextField
        label="Address Line 2"
        {...makeFormikTextFieldProps(
          formik,
          `${formikAddressValuePrefix}address2`
        )}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          label="City"
          {...makeFormikTextFieldProps(
            formik,
            `${formikAddressValuePrefix}city`
          )}
        />
        <TextField
          fullWidth
          select
          label="State"
          {...makeFormikTextFieldProps(
            formik,
            `${formikAddressValuePrefix}state`
          )}
        >
          {stateCodeOptions.map((state) => (
            <MenuItem key={state.name} value={state.name}>
              {state.abrev} - {state.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          label="Zip"
          {...makeFormikTextFieldProps(
            formik,
            `${formikAddressValuePrefix}postalCode`
          )}
        />
      </Stack>
      <TextField
        label="Country"
        {...makeFormikTextFieldProps(
          formik,
          `${formikAddressValuePrefix}country`
        )}
      />
    </>
  )
}
