import {RestDataTable} from "@lightningkite/mui-lightning-components"
import {Container} from "@mui/material"
import {AuthContext} from "App"
import PageHeader from "components/PageHeader"
import React, {FC, useContext} from "react"
import {useNavigate} from "react-router-dom"

const UserIndex: FC = () => {
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <Container maxWidth="md">
      <PageHeader title="Users" />

      <RestDataTable
        restEndpoint={session.user}
        onRowClick={(user) => navigate(`/users/${user._id}`)}
        searchFields={["email"]}
        columns={[
          {
            field: "email",
            headerName: "Email",
            flex: 1
          },
          {
            field: "isSuperUser",
            headerName: "Super User",
            type: "boolean"
          }
        ]}
      />
    </Container>
  )
}

export default UserIndex
