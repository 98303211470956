import {Box, StackProps, Typography} from "@mui/material"
import {Stack} from "@mui/system"
import React, {FC, PropsWithChildren, ReactNode} from "react"

export interface FormSectionProps {
  title?: string
  action?: ReactNode
  subtitle?: ReactNode
  disableTopPadding?: boolean
  spacing?: StackProps["spacing"]
}

export const FormSection: FC<PropsWithChildren<FormSectionProps>> = (props) => {
  const {title, subtitle, disableTopPadding, children, spacing = 3} = props

  return (
    <Box pt={disableTopPadding ? undefined : 6}>
      {title && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" fontWeight="bold">
            {title}
          </Typography>
          {props.action}
        </Stack>
      )}
      {subtitle && (
        <Typography variant="body2" sx={{color: "#888", mt: 1}}>
          {subtitle}
        </Typography>
      )}
      <Stack spacing={spacing} mt={2}>
        {children}
      </Stack>
    </Box>
  )
}

export default FormSection
