import {Condition} from "@lightningkite/lightning-server-simplified"
import {RestDataTable} from "@lightningkite/mui-lightning-components"
import {Application} from "api/sdk"
import {AuthContext} from "App"
import React, {FC, useContext} from "react"
import {getCurrentStepLabel} from "utils/helpers/stepHelpers"

export interface ApplicationTableProps {
  additionalConditions?: Condition<Application>[]
  onRowClick?: (application: Application) => void
}

const ApplicationTable: FC<ApplicationTableProps> = (props) => {
  const {additionalConditions, onRowClick} = props
  const {session} = useContext(AuthContext)

  return (
    <RestDataTable
      onRowClick={onRowClick}
      restEndpoint={session.application}
      additionalQueryConditions={additionalConditions}
      columns={[
        {
          field: "business",
          headerName: "Business",
          flex: 1,
          valueGetter: ({row}) => row.business?.name,
          renderCell: ({value}) => value ?? <i>Not provided</i>,
          sortable: false
        },
        {
          field: "trustee",
          headerName: "Trustee",
          flex: 1,
          valueGetter: ({row}) =>
            `${row.trustee?.givenName ?? ""} ${row.trustee?.surname ?? ""}`,
          renderCell: ({value}) =>
            value !== " " ? value : <i>Not provided</i>,
          sortable: false
        },
        {
          field: "status",
          headerName: "Current Step",
          valueGetter: ({row}) => getCurrentStepLabel(row),
          width: 200,
          sortable: false
        }
      ]}
    />
  )
}

export default ApplicationTable
