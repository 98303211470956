import {Typography} from "@mui/material"
import FormSection from "components/FormSection"
import React, {FC} from "react"
import {FormStepContentProps} from "../UserApplicationPage"

const Finalize: FC<FormStepContentProps> = (props) => {
  return (
    <>
      <FormSection disableTopPadding title="Thank you!">
        <Typography variant="body1">
          We&apos;re getting everything set up and will contact you if we need
          any more information.
        </Typography>
      </FormSection>
    </>
  )
}
export default Finalize
