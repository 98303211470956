import {Box, Typography} from "@mui/material"
import React, {ReactElement, ReactNode} from "react"

export interface LabeledValueProps<T> {
  label: string
  value: T | null | undefined
  valueRenderer?: (value: T) => ReactNode
}

export function LabeledValue<T>(props: LabeledValueProps<T>): ReactElement {
  const {
    label,
    value,
    valueRenderer = (value) => (
      <Typography variant="body1">{value as unknown as any}</Typography>
    )
  } = props

  return (
    <Box>
      <Typography variant="body2" sx={{color: "text.secondary"}}>
        {label}
      </Typography>

      {value === null || value === undefined || value === "" ? (
        <Typography variant="body1" fontStyle="italic">
          No value
        </Typography>
      ) : (
        valueRenderer(value)
      )}
    </Box>
  )
}
