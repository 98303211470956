import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {ExpandMore} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography
} from "@mui/material"
import {Application, SignupFormStatus, User} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import ErrorAlert from "components/ErrorAlert"
import Loading from "components/Loading"
import PageHeader from "components/PageHeader"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {applicationFilters, ApplicationFilterSlug} from "routers/AdminRoutes"
import {getCurrentStepLabel} from "utils/helpers/stepHelpers"
import {ApplicationFrom} from "./ApplicationForm"
import {ApplicationPreview} from "./ApplicationPreview"
import {ApplicationReview} from "./ApplicationReview"

export const ApplicationDetail: FC = () => {
  const {filterSlug, applicationId, userId} = useParams<{
    filterSlug: ApplicationFilterSlug
    applicationId: string
    userId: string
  }>()
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()

  const [application, setApplication] = useState<Application | null>()
  const [user, setUser] = useState<User | null>()

  if (filterSlug === undefined && userId === undefined) {
    throw new Error("Filter slug and user ID are undefined")
  }

  if (applicationId === undefined) {
    throw new Error("Application ID is undefined")
  }

  const prevPath = userId
    ? `/users/${userId}`
    : `/applications/${filterSlug as ApplicationFilterSlug}`

  const refreshApplication = async () => {
    try {
      const newApplication = await session.application.detail(applicationId)
      setApplication(newApplication)
    } catch {
      setApplication(null)
    }
  }

  const deleteApplication = async () => {
    const shouldDelete = confirm(
      "Are you sure you want to permanently delete this application?"
    )
    if (!shouldDelete) {
      return
    }
    await session.application
      .delete(applicationId)
      .then(() => navigate(prevPath))
      .catch(() => alert("Error deleting application"))
  }

  useEffect(() => {
    refreshApplication()
  }, [applicationId])

  useEffect(() => {
    application &&
      session.user
        .detail(application.owner)
        .then(setUser)
        .catch(() => setUser(null))
  }, [application?.owner])

  if (application === null) {
    return <ErrorAlert>Error loading application</ErrorAlert>
  }

  if (user === null) {
    return <ErrorAlert>Error loading application user</ErrorAlert>
  }

  if (application === undefined || user === undefined) {
    return <Loading />
  }

  const pageTitle = application.business?.name
    ? application.business.name
    : application.trustee
    ? `${application.trustee.givenName ?? ""} ${
        application.trustee.surname ?? ""
      }`
    : "New Application"

  return (
    <Container maxWidth="md">
      <PageHeader
        title={pageTitle}
        breadcrumbs={
          userId
            ? [
                ["All Users", "/users"],
                [user.email, prevPath],
                [pageTitle, ""]
              ]
            : [
                [
                  applicationFilters[filterSlug as ApplicationFilterSlug].title,
                  prevPath
                ],
                [pageTitle, ""]
              ]
        }
      >
        <AutoLoadingButton onClick={deleteApplication} color="error">
          Delete
        </AutoLoadingButton>
      </PageHeader>

      <Stack spacing={2}>
        {application.solera && (
          <Alert severity="info" variant="filled">
            <AlertTitle>Solera Status - {application.solera.id}</AlertTitle>
            {application.solera.status}
          </Alert>
        )}

        {application.review?.status === SignupFormStatus.Rejected && (
          <Alert
            severity="error"
            variant="filled"
            action={
              <AutoLoadingButton
                color="inherit"
                onClick={() =>
                  session.application
                    .modify(
                      application._id,
                      makeObjectModification(application, {
                        review: null,
                        readyForReview: true
                      })
                    )
                    .then(refreshApplication)
                    .catch(() => alert("Error updating application"))
                }
              >
                Clear Review
              </AutoLoadingButton>
            }
          >
            <AlertTitle>Application Denied</AlertTitle>
            This application has been denied
          </Alert>
        )}

        {application.readyForReview && (
          <Card
            sx={(theme) => ({
              borderLeft: `5px solid ${theme.palette.error.main}`
            })}
          >
            <CardContent>
              <ApplicationReview
                application={application}
                refreshApplication={refreshApplication}
              />
            </CardContent>
          </Card>
        )}

        <Card>
          <CardContent sx={{mt: 2}}>
            <ApplicationFrom
              application={application}
              refreshApplication={refreshApplication}
            />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <ApplicationPreview application={application} user={user} />
          </CardContent>
        </Card>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h3">View Raw Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>Current Step: {getCurrentStepLabel(application)}</p>
            <p>User: {user.email}</p>
            <Box
              sx={{
                maxWidth: "100%",
                overflowY: "scroll",
                border: "1px solid grey",
                padding: 2
              }}
            >
              <pre>{JSON.stringify(application, null, 2)}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  )
}
