import {ArrowDropDown, Refresh} from "@mui/icons-material"
import {Alert, AlertTitle, Button} from "@mui/material"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import React, {FC} from "react"
import {FormStepContentProps} from "../UserApplicationPage"

const SignDocuments: FC<FormStepContentProps> = (props) => {
  const {application, nextStep, refreshApplication} = props

  if (!application.trust) {
    return (
      <FormSection disableTopPadding title="Sign Digital Documents">
        <Alert
          severity="warning"
          action={
            <AutoLoadingButton
              size="small"
              startIcon={<Refresh />}
              color="inherit"
              onClick={() =>
                refreshApplication().catch(() => alert("Failed to refresh"))
              }
            >
              Refresh
            </AutoLoadingButton>
          }
        >
          <AlertTitle>We&apos;re getting your 401k set up!</AlertTitle>
          You will receive an email when you can return here to sign documents
        </Alert>
      </FormSection>
    )
  }

  if (!application.signature?.signed) {
    return (
      <FormSection disableTopPadding title="Sign Digital Documents">
        <Alert
          severity="warning"
          action={
            <AutoLoadingButton
              size="small"
              startIcon={<Refresh />}
              color="inherit"
              onClick={() =>
                refreshApplication().catch(() => alert("Failed to refresh"))
              }
            >
              Refresh
            </AutoLoadingButton>
          }
        >
          <AlertTitle>Waiting for signed documents</AlertTitle>A link has been
          sent to your email address with instructions for digitally signing our
          terms of service
        </Alert>
      </FormSection>
    )
  }

  return (
    <FormSection disableTopPadding title="Sign Digital Documents">
      <Alert severity="success">
        <AlertTitle>Complete!</AlertTitle>
        We have received your signed documents
      </Alert>
      <Button
        endIcon={<ArrowDropDown />}
        variant="contained"
        disabled={!application.signature?.signed}
        onClick={nextStep}
        sx={{alignSelf: "flex-start"}}
      >
        Continue
      </Button>
    </FormSection>
  )
}
export default SignDocuments
