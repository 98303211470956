import {
  AccountInfoTypes,
  Address,
  Application,
  BusinessInfo,
  OriginalPlanInfo,
  SpouseTrusteeInfo,
  TrusteeInfo,
  User
} from "api/sdk"
import {v4 as uuidv4} from "uuid"

export const getEmptyApplication = (owner: User): Application => ({
  _id: uuidv4(),
  owner: owner._id,
  trustee: emptyTrusteeInfo,
  business: emptyBusinessInfo,
  accountTypes: AccountInfoTypes.BOTH,
  investmentCountries: [],
  investmentSectors: [],
  existing: null,
  originalPlan: null,
  spouse: null,
  spouseTrusteeInfo: null,
  desiredTrustName: null,
  notes: "",
  stripe: null,
  signature: null,
  solera: null,
  trust: null,
  readyForReview: false,
  review: null
})

export const emptyBusinessInfo: BusinessInfo = {
  name: null,
  phone: null,
  startedAt: null,
  email: null,
  address: null,
  businessForm: null,
  stateOfFormation: null,
  entityIdentificationNumber: null,
  naics: null,
  fiscalYearEnd: null
}

export const emptyTrusteeInfo: TrusteeInfo = {
  givenName: null,
  middleName: null,
  surname: null,
  maidenName: null,
  gender: null,
  mailing: null,
  previous: null,
  residential: null,
  phone: null,
  socialSecurityNumber: null,
  birthday: null
}

export const emptyAddress: Address = {
  line1: null,
  line2: null,
  city: null,
  state: null,
  country: null,
  postalCode: null
}

export const emptyOriginalPlanInfo: OriginalPlanInfo = {
  originalPlanDate: null,
  originalTrustEin: null
}

export const emptySpouseTrusteeInfo: SpouseTrusteeInfo = {
  fullLegalName: null,
  email: null
}

export function camelCaseToTitle(str: string): string {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase()
  })
}
