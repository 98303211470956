import {
  AccountInfoTypes,
  BusinessForm,
  ExistingState,
  SignupFormStatus,
  SpouseParticipation
} from "api/sdk"

export const businessFormLabels: Record<BusinessForm, string> = {
  [BusinessForm.LLC]: "LLC",
  [BusinessForm.C]: "C Corporation",
  [BusinessForm.S]: "S Corporation",
  [BusinessForm.Sole]: "Sole Proprietorship",
  [BusinessForm.Partnership]: "Partnership"
}

export const existingStateLabels: Record<ExistingState, string> = {
  [ExistingState.None]: "No existing plan",
  [ExistingState.Both]: "Keep both plans",
  [ExistingState.Replace]: "Replace the existing plan"
}

export const spouseParticipationLabels: Record<SpouseParticipation, string> = {
  [SpouseParticipation.CoTrustee]: "Yes, spouse will be co-trustee",
  [SpouseParticipation.Participating]: "Yes, spouse will participate",
  [SpouseParticipation.NotParticipating]: "No, spouse will not participate",
  [SpouseParticipation.Single]: "No, I am single",
  [SpouseParticipation.Divorced]: "No, I am divorced",
  [SpouseParticipation.Widowed]: "No, I am widowed"
}

export const signupFormStatusLabels: Record<SignupFormStatus, string> = {
  [SignupFormStatus.Approved]: "Approved",
  [SignupFormStatus.ChangesRequested]: "Changes Requested",
  [SignupFormStatus.Rejected]: "Rejected"
}

export const accountTypeLabels: Record<AccountInfoTypes, string> = {
  [AccountInfoTypes.TRADITIONAL]: "Traditional",
  [AccountInfoTypes.ROTH]: "Roth",
  [AccountInfoTypes.BOTH]: "Both"
}
