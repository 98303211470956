import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {LoadingButton} from "@mui/lab"
import {Alert, MenuItem, Stack, TextField} from "@mui/material"
import {Application} from "api/sdk"
import {AuthContext} from "App"
import {AddressForm} from "components/AddressForm"
import FormSection from "components/FormSection"
import {useFormik} from "formik"
import React, {FC, useContext, useState} from "react"
import {stateCodeOptions} from "utils/constants"
import * as yup from "yup"

// Form validation schema. See: https://www.npmjs.com/package/yup#object
const validationSchema = yup.object().shape({})

export interface ApplicationFormProps {
  application: Application
  refreshApplication: () => Promise<void>
}

export const ApplicationFrom: FC<ApplicationFormProps> = (props) => {
  const {application, refreshApplication} = props

  const {session} = useContext(AuthContext)

  const [error, setError] = useState("")

  const formik = useFormik({
    initialValues: {
      ein: application.trust?.ein ?? "",
      name: application.trust?.name ?? application.desiredTrustName ?? "",
      creationDate: application.trust?.creationDate ?? "",
      stateOfRegistration:
        application.trust?.state ?? application.business.address?.state ?? "",
      address1:
        application.trust?.address?.line1 ??
        application.business.address?.line1 ??
        "",
      address2:
        application.trust?.address?.line2 ??
        application.business.address?.line2 ??
        "",
      city:
        application.trust?.address?.city ??
        application.business.address?.city ??
        "",
      state:
        application.trust?.address?.state ??
        application.business.address?.state ??
        "",
      postalCode:
        application.trust?.address?.postalCode ??
        application.business.address?.postalCode ??
        "",
      country:
        application.trust?.address?.country ??
        application.business.address?.country ??
        "United States"
    },
    validationSchema,

    onSubmit: async (values, {resetForm}) => {
      setError("")

      const modification = makeObjectModification(application, {
        trust: {
          ein: values.ein,
          name: values.name,
          address: {
            line1: values.address1,
            line2: values.address2,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            country: values.country
          },
          creationDate:
            application.trust?.creationDate ??
            new Date().toISOString().split("T")[0],
          state: values.state
        }
      })

      if (!modification) {
        return
      }

      try {
        await session.application
          .modify(application._id, modification)
          .then(() => {
            resetForm({values})
            refreshApplication()
          })
      } catch {
        setError("Error updating application")
      }
    }
  })

  return (
    <Stack gap={3}>
      <FormSection title="Trust Information" spacing={2} disableTopPadding>
        <TextField label="EIN" {...makeFormikTextFieldProps(formik, "ein")} />

        <TextField
          label="Trust Name"
          {...makeFormikTextFieldProps(formik, "name")}
        />

        <TextField
          fullWidth
          select
          label="State of Registration"
          {...makeFormikTextFieldProps(formik, "stateOfRegistration")}
        >
          {stateCodeOptions.map((state) => (
            <MenuItem key={state.name} value={state.name}>
              {state.abrev} - {state.name}
            </MenuItem>
          ))}
        </TextField>
      </FormSection>

      <FormSection title="Address">
        <AddressForm formik={formik} />
      </FormSection>

      {error && <Alert severity="error">{error}</Alert>}

      <LoadingButton
        onClick={() => {
          formik.submitForm()
        }}
        variant="contained"
        color="primary"
        loading={formik.isSubmitting}
        style={{alignSelf: "end"}}
        disabled={!formik.dirty}
      >
        {formik.dirty ? "Save Changes" : "Saved"}
      </LoadingButton>
    </Stack>
  )
}
