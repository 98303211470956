import {
  makeFormikDateTimePickerProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {Autocomplete, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {
  AccountInfoTypes,
  ExistingState,
  InvestmentSectors,
  SpouseParticipation
} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import {makeFormikRadioInputProps, RadioInput} from "components/RadioInput"
import dayjs from "dayjs"
import {useFormik} from "formik"
import React, {FC} from "react"
import {countries} from "utils/constants"
import {
  accountTypeLabels,
  spouseParticipationLabels
} from "utils/helpers/enumLabels"
import {
  camelCaseToTitle,
  emptyOriginalPlanInfo,
  emptySpouseTrusteeInfo
} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

function hasSpouse(
  spouseParticipation: SpouseParticipation | null | undefined
) {
  return (
    spouseParticipation === SpouseParticipation.Participating ||
    spouseParticipation === SpouseParticipation.NotParticipating ||
    spouseParticipation === SpouseParticipation.CoTrustee
  )
}

const validationSchema = yup.object().shape({
  planName: yup.string().required("Required"),
  accountTypes: yup.string().required("Required").nullable(),
  investmentSectors: yup
    .array()
    .min(1, "Select at least on Investment Sector")
    .required("Required"),
  spouseParticipation: yup.string().required("Required"),
  spouseFullLegalName: yup.string().when(["spouseParticipation"], {
    is: (spouseParticipation: SpouseParticipation) =>
      hasSpouse(spouseParticipation),
    then: yup.string().required("Required")
  }),
  spouseEmail: yup.string().when(["spouseParticipation"], {
    is: (spouseParticipation: SpouseParticipation) =>
      hasSpouse(spouseParticipation),
    then: yup.string().required("Required")
  }),
  existingSolo401k: yup.string().required("Required"),
  replaceExistingPlan: yup.string().when("existingSolo401k", {
    is: "Yes",
    then: yup.string().required("Required")
  }),
  originalPlanDate: yup
    .date()
    .nullable()
    .when(["existingSolo401k", "replaceExistingPlan"], {
      is: (existing: string, replace: string) =>
        existing === "Yes" && replace === "Yes",
      then: yup.date().nullable().required("Required")
    }),
  originalTrustEin: yup
    .string()
    .when(["existingSolo401k", "replaceExistingPlan"], {
      is: (existing: string, replace: string) =>
        existing === "Yes" && replace === "Yes",
      then: yup.string().required("Required")
    })
})

const industryOptions = Object.values(InvestmentSectors)
const countryOptions = countries.map((country) => country.name)

const PlanInfo: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props
  const formik = useFormik({
    initialValues: {
      planName: application.desiredTrustName ?? "",
      accountTypes: application.accountTypes ?? "",
      investmentCountries: application.investmentCountries ?? [],
      investmentSectors: application.investmentSectors ?? [],
      spouseParticipation: application.spouse ?? null,
      spouseFullLegalName: application.spouseTrusteeInfo?.fullLegalName ?? "",
      spouseEmail: application.spouseTrusteeInfo?.email ?? "",
      existingSolo401k: !application.existing
        ? ""
        : application.existing !== ExistingState.None
        ? "Yes"
        : "No",
      replaceExistingPlan: !application.existing
        ? ""
        : application.existing === ExistingState.Replace
        ? "Yes"
        : "No",
      originalPlanDate: application.originalPlan?.originalPlanDate
        ? new Date(application.originalPlan?.originalPlanDate)
        : null,
      originalTrustEin: application.originalPlan?.originalTrustEin ?? ""
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return saveProgress({
        desiredTrustName: values.planName,
        spouse: values.spouseParticipation,
        spouseTrusteeInfo: hasSpouse(values.spouseParticipation)
          ? {
              ...(application.spouseTrusteeInfo ?? emptySpouseTrusteeInfo),
              fullLegalName: values.spouseFullLegalName,
              email: values.spouseEmail
            }
          : null,
        existing:
          values.existingSolo401k === "No"
            ? ExistingState.None
            : values.replaceExistingPlan === "Yes"
            ? ExistingState.Replace
            : ExistingState.Both,
        originalPlan: {
          ...(application.originalPlan ?? emptyOriginalPlanInfo),
          originalPlanDate: values.originalPlanDate
            ? new Date(values.originalPlanDate).toISOString().split("T")[0]
            : null,
          originalTrustEin: values.originalTrustEin
        },
        accountTypes: values.accountTypes,
        investmentCountries: values.investmentCountries,
        investmentSectors: values.investmentSectors
      })
        .then(() => {
          resetForm({values})
          nextStep()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection
        disableTopPadding
        title="Desired Solo 401k Plan/Trust name"
        subtitle={
          <>
            Provide a name for the 401k plan and trust that we will set up for
            you.
            <br />
            <br />
            Typically this name is modeled after your name or your business name
            such as “John Smith 401k Plan“ or “ABC Consulting 401k Plan“.
          </>
        }
      >
        <TextField
          label="Plan/Trust name"
          {...makeFormikTextFieldProps(formik, "planName")}
        />
      </FormSection>

      <FormSection title="Account Types">
        <RadioInput
          options={Object.keys(AccountInfoTypes).map((key) => ({
            value: key,
            label: accountTypeLabels[key as AccountInfoTypes]
          }))}
          {...makeFormikRadioInputProps(formik, "accountTypes")}
        />
      </FormSection>

      <FormSection title="Investment Information">
        <Autocomplete
          fullWidth
          multiple
          options={countryOptions}
          value={formik.values.investmentCountries}
          onChange={(_e, newValue) => {
            formik.setFieldValue("investmentCountries", newValue)
          }}
          renderInput={(params) => (
            <TextField
              label="Investment Countries"
              {...params}
              error={!!formik.errors.investmentCountries}
              helperText={formik.errors.investmentCountries}
            />
          )}
        />

        <Autocomplete
          fullWidth
          multiple
          options={industryOptions}
          value={formik.values.investmentSectors}
          onChange={(_e, newValue) => {
            formik.setFieldValue("investmentSectors", newValue)
          }}
          getOptionLabel={camelCaseToTitle}
          renderInput={(params) => (
            <TextField
              label="Investment Sectors"
              {...params}
              error={!!formik.errors.investmentSectors}
              helperText={formik.errors.investmentSectors}
            />
          )}
        />
      </FormSection>

      <FormSection
        title="Spousal Participation"
        subtitle={
          <>
            Your spouse can participate in the plan if he or she performs work
            for the company that would generate earned income, even if they are
            not an employee. You can change this choice later if needed.
            <br />
            <br />
            You spouse can optionally be a 401k trustee if you would like them
            to be added as a signer on bank accounts opened for the trustee.
            Trustees have check writing authority.
          </>
        }
      >
        <RadioInput
          label="If married, will your spouse participate in the plan?"
          options={Object.values(SpouseParticipation).map(
            (sp: SpouseParticipation) => ({
              value: sp,
              label: spouseParticipationLabels[sp]
            })
          )}
          {...makeFormikRadioInputProps(formik, "spouseParticipation")}
        />

        {hasSpouse(formik.values.spouseParticipation) && (
          <>
            <TextField
              label="Spouse full legal name"
              {...makeFormikTextFieldProps(formik, "spouseFullLegalName")}
            />
            <TextField
              label="Spouse email"
              {...makeFormikTextFieldProps(formik, "spouseEmail")}
            />
          </>
        )}
      </FormSection>

      <FormSection
        title="Existing Solo 401k"
        subtitle={
          <>
            If you have an existing Solo 401k plan, you can replace it with this
            new one or keep both open. If your existing plan does not have an
            EIN, enter “N/A“.
          </>
        }
      >
        <RadioInput
          label="Do you have an existing Solo 401k for this business?"
          options={[{value: "Yes"}, {value: "No"}]}
          {...makeFormikRadioInputProps(formik, "existingSolo401k")}
        />

        {formik.values.existingSolo401k === "Yes" && (
          <>
            <RadioInput
              label="Will this plan replace the existing plan?"
              options={[{value: "Yes"}, {value: "No"}]}
              {...makeFormikRadioInputProps(formik, "replaceExistingPlan")}
            />

            {formik.values.replaceExistingPlan === "Yes" && (
              <>
                <DatePicker
                  label="Effective date of original plan"
                  {...makeFormikDateTimePickerProps(formik, "originalPlanDate")}
                  minDate={dayjs().subtract(120, "year")}
                  maxDate={dayjs()}
                />
                <TextField
                  label="Trust EIN"
                  helperText="If your original Solo 401k has its own EIN number, enter it here. If not, we will obtain an EIN for the new trust."
                  {...makeFormikTextFieldProps(formik, "originalTrustEin")}
                />
              </>
            )}
          </>
        )}
      </FormSection>

      <AutoLoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={formik.submitForm}
        sx={{mt: 4}}
      >
        Save and Continue
      </AutoLoadingButton>
    </>
  )
}

export default PlanInfo
