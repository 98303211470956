import {Box, Button, Divider, Stack} from "@mui/material"
import logo from "assets/logo.png"
import React, {FC, ReactNode} from "react"
import {NavLink} from "react-router-dom"
import {applicationFilters} from "routers/AdminRoutes"
import MyUserMenu from "./MyUserMenu"

const navItems: Array<{label: string; to: string}> = [
  ...Object.entries(applicationFilters).map(([slug, {title}]) => ({
    label: title,
    to: `/applications/${slug}`
  })),
  {label: "Users", to: "/users"}
]

export const AdminLayout: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Stack direction="row" minHeight="100vh">
      <Box
        sx={{
          p: 1,
          zIndex: 1,
          backgroundColor: "white",
          width: "20rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16)"
        }}
      >
        <img
          src={logo}
          width="80%"
          style={{marginInline: "auto", marginTop: "1rem", display: "block"}}
        />

        <Divider sx={{mb: 1, mt: 2}} />

        <MyUserMenu />

        <Divider sx={{my: 1}} />

        <Stack spacing={1}>
          {navItems.map(({label, to}) => (
            <Button
              key={to}
              component={NavLink}
              to={to}
              sx={{
                justifyContent: "start",
                "&.active": {
                  bgcolor: "primary.main",
                  color: "white"
                }
              }}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box bgcolor="background.default" width="100%" pt={3} pb={7}>
        {children}
      </Box>
    </Stack>
  )
}
